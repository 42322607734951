// Abstracts
@use "abstracts/index" as *;

.password-confirmation {
    .password-strength,
    .password-confirmation-mismatch {
        text-align: left;
        font-size: 12px;
        margin-top: -10px;

        &.very_weak {
            color: color(error-300);
        }

        &.weak {
            color: color(alert-300);
        }

        &.good, &.strong, &.good_job {
            color: color(success-300);
        }
    }

    .password-confirmation-mismatch {
        color: color(error-300);
    }
}
