// Abstracts
@use "abstracts/index" as *;

.atwho-view {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    margin-top: box-model(m);
    background: color(base-000);
    color: color(base-700);
    border: 1px solid color(base-500);
    border-radius: $base-border-radius;
    box-shadow: $base-box-shadow;
    min-width: $search-input-min-width;
    z-index: z-index(dropdowns);

    .atwho-header {

        &:hover {
            cursor: default;
        }

        @include font-size(s);
        @include font-weight(bold);

        padding: box-model(m);
        margin: box-model(m);
        cursor: pointer;
        border-bottom: solid 1px color(base-500);

        .small {
            @include font-size(s);
            @include font-weight(normal);

            float: right;
            padding-top: 2px;
            margin-right: -5px;
        }
    }

    .cur {
        background: color(primary-100);
    }

    strong {
        color: color(primary-300);
    }

    ul {
        border-radius: $base-border-radius;
        list-style: none;
        padding: 0;
        margin: auto;
        max-height: 200px;
        overflow-y: auto;

        li {
            display: block;
            padding: box-model(xxs, xs);
            cursor: pointer;
        }
    }

    small {
        font-size: smaller;
        color: color(base-500);
        font-weight: normal;
    }
}
