@use "abstracts/index" as *;

@use "default/components/forms" as *;

.multi-factor-code-control {
    input {
        @extend %form-control;
        display: inline-block;
        width: box-model(xl);
        margin-left: box-model(s);
        padding: box-model(xxs);
        text-align: center;
        &:first-of-type {
            margin-left: 0;
        }
    }
}
