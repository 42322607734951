@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

@use 'vue2-datepicker/scss/index';

.mx-datepicker-main {
    background-color: color(base-000);
}

.mx-input-append {
    vertical-align: middle;
    text-align: center;
    line-height: 22px; // icon specific, no need for mixin
}

.mx-datepicker.has-warning .mx-input-wrapper {
    input {
        border-color: color(warning-300);

        &:focus {
            box-shadow: 0 0 0 1px color(warning-300);
        }
    }
}

.mx-datepicker.has-error .mx-input-wrapper {
    input {
        border-color: color(error-300);

        &:focus {
            box-shadow: 0 0 0 1px color(error-300);
        }
    }
}

.mx-input-wrapper input {
    height: box-model(xl);
}

.mx-input {
    background-color: color(base-000);
    border: 1px solid color(base-500);
    color: color(base-700);

    &::placeholder {
        color: color(base-500);
    }

    &:disabled {
        color: color(base-500);
        background-color: color(base-100);
        border-color: transparent;
    }
}

.mx-datepicker-popup {
    box-shadow: $date-picker-box-shadow;
    border: 1px solid color(base-300);
    border-radius: $base-border-radius;
    padding: box-model(xs);
    margin-top: box-model(xs);
    min-width: $date-picker-min-width;
}

.mx-range-wrapper {
    display: flex;
    width: 100%;
}

.mx-shortcuts-wrapper {
    @include font-size(s);
    //@include line-height(xxs);
    display: flex;

    .mx-shortcuts {
        flex: 1 1 25%;
        margin-bottom: box-model(0, xs, xs);

        &::after {
            display: none;
        }
    }

}

.mx-calendar {
    color: color(base-700);
    padding: box-model(xxs);
    flex: 1 1 50%;
    float: none;
}

// Hides year buttons in date mode
.mx-calendar-header {
    color: color(base-700);
}

.mx-table {
    @include font-size(s);
    min-width: box-model(xs) * 23; // 184px if box-model(xs) = 8px

    th {
        @include font-size(s);
        @include font-weight(normal);
        background-color: transparent;
        text-transform: uppercase;
        text-align: center;
    }

    td, th {
        padding: 1px;
        width: $date-picker-cell-size + 2px;
        height: $date-picker-cell-size;
        line-height: $date-picker-cell-size;
    }

    td {
        @include line-height(xxm);
        border-radius: $date-picker-cell-size;

        &.last-month, &.next-month {
            color: color(base-700);
            opacity: .6;
        }
    }
}

.mx-table-date .cell.not-current-month {
    opacity: .6;
}

.mx-calendar-content,
.mx-panel {
    height: auto;
    width: auto;
}

.mx-calendar-content,
.mx-calendar-time {
    .cell,
    .mx-time-item {
        &:hover {
            color: inherit;
            background-color: color(primary-100);
        }

        &.disabled, &.disabled:disabled {
            background-color: color(base-100);
            opacity: .6;
            border-radius: 0;
            font-style: italic;
        }

        &.active {
            background-color: color(primary-300);
            color: color(base-000);

            &:has(+ .in-range),
            &:has(+ .hover-in-range),
            &:has(+ .active) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            + .active {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.in-range:not(.disabled) {
            background-color: color(primary-050);
        }

        &.in-range,
        &.hover-in-range {
            border-radius: 0;

            + .active,
            + :hover {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

.mx-panel-year, .mx-panel-month {
    display: flex;
    flex-wrap: wrap;

    .cell {
        @include line-height(xs);
        flex: 1 1 30%;
        width: auto;
        margin: box-model(xxxs);
        border-radius: $base-border-radius;
        background-color: color(primary-300);
        color: color(base-000);
        padding: math.div(box-model(s), 2) box-model(s);

        &:not(.disabled):hover {
            background-color: color(primary-305);
            color: color(base-000);
        }

        &:not(.disabled):active {
            background-color: color(primary-500);
            color: color(base-000);
        }
    }
}

// Disable the animation
.mx-zoom-in-down-enter-active,
.mx-zoom-in-down-leave-active {
  opacity: 1;
  transform: none;
  transition: none;
}

.mx-zoom-in-down-enter,
.mx-zoom-in-down-leave-to {
  opacity: 1;
  transform: none;
}

.mx-time-column {
    border-left: color(base-300) 1px solid;

    .mx-time-list::after {
        height: auto;
    }

    .mx-time-item {
        &:hover {
            background-color: color(base-100);
        }

        &.disabled {
            background-color: transparent;
        }
    }
}

.mx-icon-calendar, .mx-icon-clear {
    color: color(base-700);
}

.mx-time {
    background-color: color(base-000);
}
