// Abstracts
@use "abstracts/index" as *;

.horizontal-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: box-model(m, 0);

    &.large {
        padding: box-model(l, 0);
    }

    &.with-parent {
        padding-top: 0;
    }

    .line {
        flex: 1 1 auto;
        height: 0;
        border-top: 1px solid color(base-300);
    }

    .content {
        flex: 0 1 auto;
        padding: 0 box-model(m);
        color: color(base-600);
    }
}
