@charset "UTF-8";

@use "sass:math";
@use "sass:meta";

// Normalization
@use "normalize.css/normalize.css";

// Main manifest file

// Abstracts
@use "abstracts/index" as *;

// Base styles

@use "default/layout";
@use "default/commons";

// Components

@use "default/components/alerts";
@use "default/components/badges";
@use "default/components/buttons";
@use "default/components/forms";
@use "default/components/forms/multi-factor-code-control";
@use "default/components/forms/password-confirmation";
@use "default/components/horizontal-divider";
@use "default/components/icons" as components-icons;
@use "default/components/loader";
@use "default/components/notifications";
@use "default/components/tooltips";

// Pages
@use "default/pages/auth";

// Third party libraries
@use "vendor/index";

// Small down
@media (max-width: $screen-sm-max) {
    @include meta.load-css("small-down/pages/auth.scss");
}


