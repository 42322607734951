@use "@simonwep/pickr/src/scss/themes/monolith";
@use "pickr";
@use "diff2html/bundles/css/diff2html.min";

@use "at-who";
@use "cropper";
@use "mobiscroll";
@use "quill";
@use "select2";
@use "vue-multiselect";
@use "vue2-datepicker";
@use "vue-draggable";

@use "intl-tel-input/build/css/intlTelInput.css";

// intl-tel-input
:root {
    --iti-path-flags-1x: url('../img/flags.webp');
    --iti-path-flags-2x: url('../img/flags@2x.webp');
    --iti-path-globe-1x: url('../img/globe.webp');
    --iti-path-globe-2x: url('../img/globe@2x.webp');
}
