@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.pickr {
    position: absolute;

    .pcr-button {
        height: box-model(xl);
        width: box-model(xl);
        vertical-align: top;

        &:focus {
            box-shadow: none;
        }

        &::before, &::after {
            top: box-model(xs);
            left: box-model(xs);

            height: box-model(m);
            width: box-model(m);
        }

        &::after {
            box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.3) inset;
        }
    }
}

.pcr-app {
    border-radius: $base-border-radius;
    border: 1px solid color(base-300);
    box-shadow: $color-picker-box-shadow;

    button.pcr-active {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px color(base-700);
    }

    .pcr-interaction {
        flex-flow: row-reverse;

        .pcr-save, .pcr-cancel {
            @include font-size(base);
            @include line-height(xs);
            @include ellipsis(false);

            appearance: none;
            background-color: color(base-300);
            border-radius: $base-border-radius;
            border: 0;
            box-shadow: none;
            box-sizing: border-box;
            color: color(base-700);
            cursor: pointer;
            display: inline-block;
            padding: math.div(box-model(s), 2) box-model(s);
            text-align: center;
            transition: $base-text-transition, $base-background-color-transition;
            vertical-align: top;
            margin-left: box-model(xs);
            margin-right: box-model(xs);
            letter-spacing: normal;

            &:hover {
                background-color: color(base-305);
                color: color(base-700);
                text-decoration: none; // This is for `a.btn`
                box-shadow: none;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .pcr-save {
            background-color: color(primary-300);
            color: color(base-000);
            margin-left: 0;

            &:hover {
                background-color: color(primary-305);
                color: color(base-000);
            }
        }
    }
}
